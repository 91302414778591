@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.common_width {
  width: 1400px;
  margin: 0rem auto;
}

nav,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

body {
  font-family: "Inter", sans-serif;
  background: #0b0b0d;
}

header {
  height: 130px;
  justify-content: space-between;
  position: relative;
}

header,
header nav,
header nav li {
  display: flex;
  align-items: center;
}

header nav li p {
  color: #fff;
  font-size: 17px;
  font-weight: 300;
  line-height: 21px;
}

header>img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  transform: translate(-50%, -50%);
}

header nav>*+* {
  margin-left: 3rem;
}

header nav li>*+* {
  margin-left: 12px;
}

.Privacy {
  color: #fff;
}

.Privacy header+.common_width p {
  line-height: 30px;
}

.Privacy header+.common_width {
  padding: 3rem 0rem;
}

.Privacy header+.common_width p {
  font-size: 18px;
  line-height: 40px;
}

.Privacy header+.common_width>*+* {
  margin-top: 2rem;
}

.Privacy header+.common_width li {
  margin-top: 1rem;
  font-size: 18px;
  line-height: 40px;
}

.Privacy a {
  color: #fff !important;
}

.contact_form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.contact_form .input_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.contact_form .input_box:last-child {
  margin-bottom: 0;
}

.contact_form .input_box label {
  color: white;
}

.contact_form .input_box :where(input, select) {
  font-size: 16px;
  height: 50px;
  background: #dddbdb;
  border-radius: 5px;
  padding: 10px 14px;
  color: black;
  font-weight: 400;
  outline: none;
  border: none;
}

.contact_form ::placeholder {
  color: rgba(0, 0, 0, 0.8);
}

.contact_form .input_box textarea {
  background: #dddbdb;
  font-size: 16px;
  border-radius: 5px;
  height: 150px;
  padding: 10px 14px;
  outline: none;
  border: none;
}

.contact_form button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  outline: none;
  border: none;
  background: #f89b1d;
  cursor: pointer;
  color: white;

}

.contact-content p {
  margin-top: 1rem;
  line-height: 40px;
  font-size: 18px;
}

.contact-content p.address {
  line-height: 24px;
}


@media screen and (max-width: 1450px) {
  .common_width {
    width: 90%;
  }
}