main {
  height: 1037px;
  display: flex;
  flex-direction: column;
  position: relative;
}

main>img {
  width: 100%;
  height: 1037px;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-filter: grayscale(100%);
  object-fit: cover;
  filter: brightness(50%) grayscale(100%);
}

main .main_content_area>a {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  cursor: pointer;
  animation-name: zoomin_out;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: 0.3s ease all;
}

@keyframes zoomin_out {
  from {
    bottom: 40px;
  }

  to {
    bottom: 30px;
  }
}

main .main_content_area {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

main .main_content_area h1 {
  font-family: Inter;
  font-size: 80px;
  font-weight: 900;
  line-height: 154px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

main .main_content_area p {
  font-family: Inter;
  font-size: 40px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  width: 70%;
  margin-left: auto;
  margin-top: 1rem;
  margin-bottom: 4rem;
  margin-right: auto;
}

main .main_content_area .input_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

main .main_content_area .input_wrapper input {
  height: 60px;
  width: 340px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
  font-size: 19px;
  padding: 0rem 1rem;
  outline: none;
}

main .main_content_area .input_wrapper>a {
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-radius: 10px;
  font-size: 19px;
  background-color: #f99b1c;
  color: #fff;
}

/* EasyServices */
.EasyServices {
  padding: 10rem 0rem;
}

.grid_2 {
  grid-column: span 2;
}

.works_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 3rem;
}

.works_wrapper h2 {
  font-size: 26px;
  color: #fff;
}

.works_wrapper p {
  font-size: 20px !important;
}

.EasyServices>h3 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.EasyServices>h1 {
  font-family: Inter;
  font-size: 160px;
  font-weight: 900;
  line-height: 194px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.service_cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 10rem;
  color: #fff;
}

.ServiceCard h1 {
  font-family: Inter;
  font-size: 29px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  width: 70%;
  height: 80px;
}

.ServiceCard p {
  font-family: Inter;
  font-size: 22px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
}

/* HowitWorks */
.HowitWorks {
  padding: 10rem 0rem;
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  align-items: center;
  grid-gap: 1rem;
}

.HowitWorks .buttons_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.HowitWorks .buttons_wrapper button {
  height: 70px;
  width: 220px;
  font-family: Inter;
  font-size: 22px;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
}

.HowitWorks .buttons_wrapper button.active {
  background: #f99b1c;
  color: #fff;
}

.HowitWorks .buttons_wrapper button:not(.active) {
  border: 1px solid #fff;
}

.HowitWorks .buttons_wrapper button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  border-right: none;
}

.HowitWorks .buttons_wrapper button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: none;
}

.HowitWorks h1 {
  font-family: Inter;
  font-size: 100px;
  font-weight: 800;
  line-height: 162px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.HowitWorks p {
  font-family: Inter;
  font-size: 30px;
  font-weight: 300;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-top: 1rem;
}

.HowitWorks .left_side>a {
  height: 91px;
  width: 380px;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0px;
  font-size: 33px;
  font-weight: 800;
  line-height: 40px;
  border: 3px solid #ffffff;
  margin-top: 3rem;
}

.HowitWorks .how_slides_area img {
  width: 100%;
}

.HowitWorks .splide__arrow svg {
  fill: #fff !important;
}

.HowitWorks .splide__arrow--prev {
  display: none;
}

/* AcceptingBusiness */
.AcceptingBusiness>h1 {
  font-family: Inter;
  font-size: 118px;
  font-weight: 800;
  line-height: 167px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6rem;
}

.AcceptingBusiness>p {
  font-family: Inter;
  font-size: 38px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 2rem;
}

.AcceptingBusiness .boxes_accept {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 10rem;
}

.AcceptingBusiness .AcceptBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  height: 199px;
  border: 3px solid #fff;
  border-radius: 0px;
}

.AcceptingBusiness .AcceptBox p {
  margin-top: 20px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

/* Brands */
.Brands {
  margin-bottom: 10rem;
}

.Brands>h1,
.Faq>h1 {
  font-family: Inter;
  font-size: 118px;
  font-weight: 800;
  line-height: 157px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
}

.Faq>h1 {
  margin-bottom: 8rem;
}

.Brands_Slider>* {
  padding: 0px !important;
}

.Brands_Slider .img_wrapper {
  width: 100%;
  height: 200px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.Brands_Slider li {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Brands_Slider li img {
  width: 100%;
}

.Brands_Slider li .long_img {
  width: 80%;
}

/* GetNews */
.GetNews {
  padding: 8rem 0rem;
}

.GetNews h1 {
  font-family: Inter;
  font-size: 114px;
  font-weight: 700;
  line-height: 143px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: 70%;
  /* text-transform: uppercase; */
}

.GetNews .GetNewsSliderArea {
  margin-top: 5rem;
}

.GetNews .splide__pagination__page {
  height: 18.996023178100586px;
  width: 18.996023178100586px;
  border-radius: 50%;
  background: #dde0e4;
  transform: scale(1);
  opacity: 1 !important;
}

.GetNews .splide__pagination__page:hover {
  background: #f99b1c;
}

.GetNews .splide__pagination__page.is-active {
  transform: scale(1) !important;
  height: 18.996023178100586px !important;
  width: 56.98807144165039px !important;
  border-radius: 10px;
  background: #f99b1c;
}

.GetNews li {
  display: flex;
  justify-content: center;
}

.NewsReview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 80%;
  padding: 3rem 4rem;
  background-image: url("./assets/img/quote.png");
  background-repeat: no-repeat;
  background-position: 40px 100px;

  box-shadow: 0px 1px 25px 0px #c4c4c414;
}

.NewsReview>img {
  width: 150px;
  height: 150px;
  margin-bottom: 2rem;
  border-radius: 50%;
  object-fit: cover;
}

.NewsReview>h1 {
  margin-bottom: 0rem;
  font-size: 20px;
  line-height: unset;
  text-align: center;
}

.NewsReview p {
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 10px 0rem;
  text-align: center;
  color: #ffffff;
  width: 65%;
}

.GetNews .splide__pagination {
  bottom: -3rem;
}

/* FaqQuestion */
.Faq {
  padding: 8rem 0rem;
  padding-top: 10rem;
}

.FaqQuestion {
  width: 80%;
  margin-left: auto;
  margin-bottom: 2rem;
  margin-right: auto;
}

.FaqQuestion .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  cursor: pointer;
}

.FaqQuestion .head h1 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.FaqQuestion p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.Pricing>p {
  font-family: Inter;
  font-size: 29px;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
}

#border_add {
  box-shadow: 0px 1px 25px 0px #c4c4c414;
}

.pricing_cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0rem;
  margin-bottom: 10rem;
}

.PricingCard {
  color: #fff;
  padding: 2rem 2rem;
}

.PricingCard>p {
  font-family: Inter;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.PricingCard>h1 {
  font-family: Inter;
  font-size: 49px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  margin: 2rem 0rem;
}

.PricingCard ul>*+* {
  margin-top: 1rem;
}

.PricingCard li {
  display: block;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.PricingCard button {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 4rem;
  background: transparent;
  border: none;
  outline: none;
  font-family: Inter;
  font-size: 15px;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: 0em;
  color: #000;
  font-weight: 600;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.Footer {
  display: grid;
  /* align-items: center; */
  grid-template-columns: 1fr 0.25fr 0.25fr 0.35fr 0.5fr 0.5fr 0.25fr;
  color: #fff;
  padding: 6rem 0rem;
  font-family: Inter;
}

.Footer a {
  color: #fff;
}

.Footer .col {
  max-width: 400px;
}

.Footer .col p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.Footer ul>*+* {
  margin-top: 1rem;
}

.Footer ul li {
  padding: 10px;
}

.Footer .head {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.Footer li a {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #858586;
}

.Pricing>h1 {
  margin-bottom: 0px;
}

.Pricing>p {
  margin-bottom: 6rem;
}

.FormWrapper {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.FormWrapper form {
  width: 800px;
  position: relative;
  padding: 3rem;
  border-radius: 10px;
  background-color: #fff;
}

.close_icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.FormWrapper form h1 {
  text-align: center;
}

.FormWrapper .input_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 3rem;
}

.FormWrapper .input_grid label {
  color: #000;
}

.FormWrapper .input_grid input {
  height: 50px;
  width: 100%;
  display: block;
  padding: 1rem 1rem;
  margin-top: 1rem;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid rgb(152, 151, 151);
}

.FormWrapper .input_grid button {
  grid-column: span 2;
  height: 50px;
  border: none;
  outline: none;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {

  .EasyServices>h1,
  .HowitWorks h1,
  .AcceptingBusiness>h1,
  .Brands>h1,
  .GetNews h1,
  .Faq>h1 {
    font-size: 50px;
    line-height: unset;
  }
}

@media screen and (max-width: 1250px) {

  .pricing_cards,
  .AcceptingBusiness .boxes_accept,
  .works_wrapper,
  .service_cards {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  main .main_content_area p {
    width: 100%;
  }

  main .main_content_area h1 {
    line-height: unset;
    font-size: 70px;
  }

  .HowitWorks {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .HowitWorks {
    grid-template-columns: 1fr;
  }

  .grid_2 {
    grid-column: span 1;
  }
}

@media screen and (max-width: 980px) {
  header nav:first-child {
    display: none;
  }

  header>img {
    position: static;
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 852px) {
  .FormWrapper form {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .FormWrapper form h1 {
    align-self: center;
  }

  .FormWrapper .input_grid {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {

  .Footer,
  .pricing_cards {
    grid-template-columns: 1fr;
  }

  .FaqQuestion {
    width: 100%;
  }

  .NewsReview {
    width: 100%;
  }

  .NewsReview {
    padding: 2rem;
  }

  .NewsReview p {
    width: 100%;
  }
}

@media screen and (max-width: 665px) {

  main .main_content_area p,
  .Pricing>p {
    font-size: 20px;
    line-height: unset;
  }

  main .main_content_area h1 {
    font-size: 45px;
  }

  .EasyServices>h1,
  .HowitWorks h1,
  .AcceptingBusiness>h1,
  .Brands>h1,
  .GetNews h1,
  .Faq>h1 {
    font-size: 30px;
  }

  .ServiceCard h1 {
    font-size: 20px;
    width: 100%;
    margin-bottom: 0px;
  }

  .ServiceCard p {
    font-size: 18px;
  }

  .pricing_cards,
  .AcceptingBusiness .boxes_accept,
  .works_wrapper,
  .service_cards {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 550px) {
  .FormWrapper form {
    padding: 1rem;
  }

  .FormWrapper .input_grid {
    grid-gap: 10px;
  }

  main .main_content_area .input_wrapper {
    flex-direction: column;
  }

  main .main_content_area .input_wrapper button,
  main .main_content_area .input_wrapper input {
    width: 100%;
  }

  .HowitWorks .buttons_wrapper button {
    width: 120px;
  }

  .HowitWorks .left_side>button {
    width: 150px;
    font-size: 15px;
    height: 45px;
  }

  .Privacy header+.common_width h1 {
    font-size: 25px;
  }

  .Privacy header+.common_width p,
  .Privacy header+.common_width li {
    font-size: 15px;
    line-height: unset;
  }

  header nav>*+* {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 430px) {
  .FormWrapper form h1 {
    font-size: 24px;
  }

  .FormWrapper .input_grid>* {
    grid-column: span 2;
  }
}